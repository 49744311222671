import {StringMap, TOptions} from 'i18next';
import React, {ChangeEvent, forwardRef, InputHTMLAttributes, Ref} from 'react';
import {useTranslation} from 'react-i18next';

type CustomFileInputProps = InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
}

const CustomFileInput = forwardRef((props: Readonly<CustomFileInputProps>, ref: Ref<HTMLInputElement>) => {
    const {t} = useTranslation();

    const {
        id,
        multiple,
        label = t('components.Form.Select file', {count: multiple ? 2 : 1}),
        name,
        ...rest
    } = props;

    const fileInputCallback = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        const {files} = target;

        const {
            multiple,
            label = t('components.Form.Select file', {count: multiple} as TOptions<StringMap>),
        } = props;

        const nextSibling = target.nextElementSibling as HTMLElement;

        if (!nextSibling) {
            return;
        }

        if (files && files.length > 0) {
            nextSibling.innerText = files.length > 1
                ? t('components.Form.{count} file selected', {count: files.length})
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                : files[0]!.name;
        } else {
            nextSibling.innerText = label ?? '';
        }
    };

    const onInput = rest.onInput || function () {
    };
    rest.onInput = (e: ChangeEvent<HTMLInputElement>) => {
        onInput(e);
        fileInputCallback(e);
    };

    return (
        <div className="custom-file mb-2">
            <input
                multiple={multiple}
                type="file"
                className="custom-file-input"
                name={name}
                ref={ref}
                {...rest} />

            <label className="custom-file-label" style={{color: '#6c757d'}} htmlFor={id}>{label}</label>
        </div>
    );
});

CustomFileInput.displayName = 'CustomFileInput';

export {
    CustomFileInput
};
