import { ChangeEvent, CSSProperties, PureComponent } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import ErrorMessage from '../ErrorMessage';
import { ErrorObject } from '../ErrorMessage/ErrorMessage';
import * as Icons from '../Icons';
import LoadingIndicator from '../LoadingIndicator';
import PopUp from '../PopUp';
import { DeleteItem, ItemToDelete, ItemToDeleteState } from './DeleteItem';

interface DeleteCardProps extends WithTranslation {
    title: string;
    description: string;
    items: Array<ItemToDelete>;
    onDelete: (items: Array<ItemToDelete>) => void;
    onClose: () => void;
    errorMessage?: ErrorObject;
    isDeleting?: boolean;
}

interface DeleteCardState {
    items: Array<ItemToDeleteState>;
    userInput: string;
    consentChecked: boolean;
    deleteEnabled: boolean;
    errorMessage?: ErrorObject;
}

class DeleteCard extends PureComponent<DeleteCardProps, DeleteCardState> {

    private readonly deleteMatchRandom = Math.round(Math.random() * 100_000);

    public readonly state: Readonly<DeleteCardState> = {
        items: [],
        userInput: '',
        consentChecked: false,
        deleteEnabled: false,
    };

    get textToDelete() {
        return `delete-${this.deleteMatchRandom}`;
    }

    componentDidMount() {
        const { items } = this.props;

        const itemsToDelete = items.map(( item ) => ({...item, checked: true } as ItemToDeleteState));

        this.setState({ items: itemsToDelete });
    }

    componentDidUpdate(prevProps: Readonly<DeleteCardProps>, prevState: Readonly<DeleteCardState>) {
        const { userInput: prevUserInput, consentChecked: prevConsentChecked } = prevState;
        const { userInput, consentChecked } = this.state;

        if (prevUserInput !== userInput || prevConsentChecked !== consentChecked ) {
            this.setState({ deleteEnabled: this.validateConsentInput(), errorMessage: undefined });
        }
    }

    validateConsentInput = () : boolean => this.state.userInput === this.textToDelete && this.state.consentChecked;

    onCheckChanged = (key: number, { target } : ChangeEvent<HTMLInputElement>) => {
        const { items } = this.state;
        const detachedItems =  [...items ];

        if (key in detachedItems) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const itemToDelete = detachedItems[key]!;
            const detachedItem =  {...itemToDelete };

            detachedItem.checked = target.checked;

            detachedItems[key] = detachedItem;
            this.setState({ items: detachedItems });
        }
    }

    onClose = () => {
        const { onClose } = this.props;
        onClose();
    }

    onDelete = () => {
        const { t } = this.props;

        if (!this.validateConsentInput()) {
            this.setState({ errorMessage: t('components.DeleteCard.You have to consent to delete') });

            return;
        }

        const { items } = this.state;
        const { onDelete } = this.props;

        const itemsToDelete = items.filter((item) => item.checked);

        onDelete(itemsToDelete);
    }

    handleUserInput = ({ target } : ChangeEvent<HTMLInputElement>) => this.setState({ userInput: target.value });
    handleConsentChange = ({ target } : ChangeEvent<HTMLInputElement>) => this.setState({ consentChecked: target.checked });

    renderItems() {
        const { t, isDeleting = false } = this.props;

        const { items } = this.state;

        if (isDeleting || !items || items.length === 0) {
            return <LoadingIndicator preventUnload={isDeleting} />;
        }

        const { userInput, consentChecked } = this.state;

        const textToDelete = this.textToDelete;

        return (<>
            {items.map((item, key) => (
                <DeleteItem t={t} key={key} item={item} onCheckChanged={(input) => this.onCheckChanged(key, input)} />
            ))}

            <div style={{marginTop:'2em'}}>
                <p>
                    <Trans i18nKey='components.DeleteCard.Please type to confirm' >
                        Please type <strong title={t('components.DeleteCard.Text to enable delete button')} className='font-weight-bold'>{{textToDelete} as any}</strong> to confirm:
                    </Trans>
                </p>

                <input type="text" className='w-100 p-2' value={userInput} onChange={this.handleUserInput} />
            </div>

            <div style={{marginTop:'1em'}}>
                <input id='consent-delete-checkbox' type="checkbox" className='checkbox-size' style={{marginRight:'1em'}} checked={consentChecked} onChange={this.handleConsentChange} />
                <label htmlFor='consent-delete-checkbox' style={{display:'inline-block'}}>
                    {t('components.DeleteCard.I consent this information can be deleted')}
                </label>
            </div>
        </>);
    }

    private readonly popUpStyle: CSSProperties = {
        overflow: 'auto',
    };

    render() {
        const { t, items: itemsToDelete, title, description, errorMessage, isDeleting = false } = this.props;

        if (!itemsToDelete) {
            return null;
        }

        const { deleteEnabled, errorMessage: internalErrorMessage } = this.state;

        return (
            <PopUp style={this.popUpStyle} >
                <div style={{float:'right',position: 'relative',top: '-1em',right: '-1em',}} onClick={this.onClose}>
                    <Icons.Close/>
                </div>

                <ErrorMessage message={errorMessage || internalErrorMessage} />

                <h2>{title}</h2>
                <p>{description}.</p>

                {this.renderItems()}

                <div style={{float:'right'}}>
                    <button className="delete-btn" disabled={!deleteEnabled || isDeleting} onClick={this.onDelete} >{t('components.DeleteCard.Delete')}</button>
                </div>
            </PopUp>
        );
    }
}

const DeleteCardWithTranslation = withTranslation()(DeleteCard);

export { DeleteCardWithTranslation as DeleteCard };
