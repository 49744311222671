import { StringHelper } from '../../utils';

export class FileForUpload {
    constructor(file: globalThis.File) {
        if (!(file instanceof globalThis.File)) {
            throw new TypeError('File parameters is not an instance of window.File');
        }

        this.file = file;
        this.name = StringHelper.getFileName(file.name, true);
        this.fileName = StringHelper.getFileName(file.name);
    }

    public readonly file: globalThis.File;
    public readonly name: string;
    public readonly fileName: string;

    static fromFile(file: globalThis.File) {
        return new FileForUpload(file);
    }
}