import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import ReactSelect, { ActionMeta, CSSObjectWithLabel, GroupBase, SingleValue, OnChangeValue, OptionProps, Props, StylesConfig, ContainerProps } from 'react-select';

type SelectIsMulti = false;

export type SelectOption = {
    label: string;
    value: number | string;
}

export type SelectProps<Option = SelectOption, IsMulti extends boolean = SelectIsMulti, Group extends GroupBase<Option> = GroupBase<Option>> = Props<Option, IsMulti, Group> &
    WithTranslation & {
    showWarningBorder?: boolean;
    value?: SingleValue<SelectOption>;
}

type SelectOnChangeValue<Option = SelectOption, IsMulti extends boolean = SelectIsMulti> = OnChangeValue<Option, IsMulti>;

type SelectStylesConfig<Option = SelectOption, IsMulti extends boolean = SelectIsMulti, Group extends GroupBase<Option> = GroupBase<Option>> = StylesConfig<Option, IsMulti, Group>;

type MultiSelectContainerProps<Option = SelectOption, IsMulti extends boolean = SelectIsMulti, Group extends GroupBase<Option> = GroupBase<Option>> = ContainerProps<Option, IsMulti, Group>

class Select extends Component<SelectProps> {

    private readonly customStyle: SelectStylesConfig = {
        control: (base: CSSObjectWithLabel, option: MultiSelectContainerProps)=> ({
            ...base,
            minHeight: '3.625rem',
        }),
    };

    onChange = (newValue: SelectOnChangeValue, actionMeta: ActionMeta<SelectOption>) => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(newValue, actionMeta);
        }
    }

    public render() {
        const {showWarningBorder = false, options = [], onChange, ...rest} = this.props;

        const opts = Array.from(options);

        return (
            <ReactSelect
                styles={this.customStyle}
                onChange={this.onChange}
                options={opts}
                {...rest} />
        );
    }
}

const SelectWithTranslation = withTranslation()(Select);

export { SelectWithTranslation as Select };