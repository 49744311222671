import { ApiException } from '../../api/vincotte.via.api';
import { ApiExceptionHelper } from '../../utils';

export type ErrorObject = Error | string | Array<string> | ApiException | undefined;

export interface ErrorMessageProps {
    message?: ErrorObject;
}

export function ErrorMessage({ message } : ErrorMessageProps) {
    if (!message) {
        return null;
    }

    const parsedMessage = message instanceof Error
        ? message.message
        : message instanceof ApiException
            ? ApiExceptionHelper.getMessage(message)
            : message;

    if (Array.isArray(parsedMessage)) {
        return (
            <>
                {parsedMessage.length > 0 &&
                    <h2 className='bg-danger' >{parsedMessage.map((m) => <>{m} <br/></>)}</h2>
                }
            </>
        );
    }

    return (
        <>
            {parsedMessage && parsedMessage.trim().length > 0 && <h2 className='bg-danger' >{parsedMessage}</h2>}
        </>
    );
}
