import { ComponentPropsWithoutRef, PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApiException, ProductionLineDto, ProductionLinesClient, ProductionLinesVm, ProductionLineVm } from '../../api/vincotte.via.api';
import { withAxiosService, WithAxiosServiceProps } from '../../HOC/withAxiosService';
import ConfirmationMessage, { Button, Header, Message } from '../ConfirmationMessage';
import DeleteCard from '../DeleteCard';
import { ItemToDelete } from '../DeleteCard/DeleteItem';
import { ErrorObject } from '../ErrorMessage/ErrorMessage';

export type ProductionUnitToDelete = ProductionLinesVm | ProductionLineDto | ProductionLineVm;

type ProductionUnitDeleteCardProps = WithTranslation &
    WithAxiosServiceProps<'ProductionLinesClient', ProductionLinesClient> & {
    productionUnits: Array<ProductionUnitToDelete>;
    onDelete: () => void;
    onClose: () => void;
};

type ProductionUnitDeleteCardState = {
    productionUnits: Array<ItemToDelete>;
    errorMessage?: ErrorObject;
    isDeleting: boolean;
    isLoading: boolean;
    canDelete: boolean;
}

class ProductionUnitDeleteCard extends PureComponent<ProductionUnitDeleteCardProps, ProductionUnitDeleteCardState>  {

    public readonly state: Readonly<ProductionUnitDeleteCardState> = {
        productionUnits: [],
        isDeleting: false,
        isLoading: true,
        canDelete: true,
    };

    componentDidMount() {
        const { productionUnits, ProductionLinesClient } = this.props;

        const promises: Array<Promise<boolean>> = productionUnits.map((productionUnit: ProductionUnitToDelete) => (
            ProductionLinesClient.canDeleteProductionLine(productionUnit.id || 0).catch((error: ApiException) => {
                this.setState({ errorMessage: error });
                return true;
            })
        ));

        Promise.all(promises).then((response) => {
            return response.reduce((prev, current) => prev && !current, true);
        }).then((canDelete: boolean) => {
            this.setState({ isLoading: false, canDelete });
        });
    }

    onDelete = (productionUnitsToDelete: Array<ItemToDelete>) => {
        this.setState({ errorMessage: undefined, isDeleting: true });

        const { onDelete, ProductionLinesClient } = this.props;

        const promises = productionUnitsToDelete.map((productionUnit) => ProductionLinesClient.deleteProductionLine(productionUnit.id));

        Promise.all(promises).then(() => onDelete())
            .catch((error: ApiException) => this.setState({ errorMessage: error, isDeleting: false }));
    }

    onClose = () => this.props.onClose();

    render() {
        const { t, productionUnits } = this.props;

        const { errorMessage, isDeleting, isLoading, canDelete } = this.state;

        if (isLoading || !productionUnits || productionUnits.length === 0) {
            return null;
        }

        if (!canDelete) {
            return (<ConfirmationMessage>
                <Header>{t('components.ProductionUnitDeleteCard.You cannot delete the production unit')}</Header>

                <Message>
                    <p>{t('components.ProductionUnitDeleteCard.To delete this production unit you must first delete all associated assets')}</p>
                </Message>

                <Button className='btn-vincotte-padding secondary-btn' onClick={this.onClose}>
                    {t('components.ProductionUnitDeleteCard.Close')}
                </Button>
            </ConfirmationMessage>);
        }

        const productionUnitsToDelete = productionUnits.map((productionUnit) => (
            {
                id: productionUnit.id,
                reference: productionUnit.id?.toString(),
                name: productionUnit.name,
                summary: {}
            } as ItemToDelete
        ));

        const title = t('components.ProductionUnitDeleteCard.Are you sure you want to delete this production unit?');
        const description = t('components.ProductionUnitDeleteCard.By deleting the following production unit, you will also delete the related data');

        return (
            <DeleteCard
                title={title}
                description={description}
                onClose={this.onClose}
                onDelete={this.onDelete}
                items={productionUnitsToDelete}
                errorMessage={errorMessage}
                isDeleting={isDeleting} />
        );
    }
}

const ProductionUnitDeleteCardWithTranslation = withTranslation()(ProductionUnitDeleteCard);
const ProductionUnitDeleteCardWithProductionLinesClient = withAxiosService(ProductionLinesClient)<
    'ProductionLinesClient',
    ComponentPropsWithoutRef<typeof ProductionUnitDeleteCardWithTranslation>
>(ProductionUnitDeleteCardWithTranslation);

export { ProductionUnitDeleteCardWithProductionLinesClient as ProductionUnitDeleteCard };
