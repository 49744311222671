import { ComponentPropsWithoutRef, PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApiException, ClientByMonikerVm, ClientsClient, ClientsVm } from '../../api/vincotte.via.api';
import { WithAxiosServiceProps, withAxiosService } from '../../HOC/withAxiosService';
import ConfirmationMessage, { Button, Header, Message } from '../ConfirmationMessage';
import DeleteCard from '../DeleteCard';
import { ItemToDelete } from '../DeleteCard/DeleteItem';
import { ErrorObject } from '../ErrorMessage/ErrorMessage';

export type CompanyToDelete = ClientsVm | ClientByMonikerVm;

type CompanyDeleteCardProps = WithTranslation &
    WithAxiosServiceProps<'ClientsClient', ClientsClient> & {
    companies: Array<CompanyToDelete>;
    onDelete: () => void;
    onClose: () => void;
};

type CompanyDeleteCardState = {
    companies: Array<ItemToDelete>;
    errorMessage?: ErrorObject;
    isDeleting: boolean;
    isLoading: boolean;
    canDelete: boolean;
}

class CompanyDeleteCard extends PureComponent<CompanyDeleteCardProps, CompanyDeleteCardState>  {

    public readonly state: Readonly<CompanyDeleteCardState> = {
        companies: [],
        isDeleting: false,
        isLoading: true,
        canDelete: true,
    };

    componentDidMount() {
        const { companies, ClientsClient } = this.props;

        const promises: Array<Promise<boolean>> = companies.map((company: CompanyToDelete) => (
            ClientsClient.canDeleteClient(company.moniker || '').catch((error: ApiException) => {
                this.setState({ errorMessage: error });
                return true;
            })
        ));

        Promise.all(promises).then((response) => {
            return response.reduce((prev, current) => prev && !current, true);
        }).then((canDelete: boolean) => {
            this.setState({ isLoading: false, canDelete });
        });
    }

    onDelete = (companiesToDelete: Array<ItemToDelete>) => {
        this.setState({ errorMessage: undefined, isDeleting: true });

        const { onDelete, ClientsClient } = this.props;

        const promises = companiesToDelete.map((company) => ClientsClient.deleteClient(company.reference));

        Promise.all(promises).then(() => onDelete())
            .catch((error: ApiException) => this.setState({ errorMessage: error, isDeleting: false }));
    }

    onClose = () => this.props.onClose();

    render() {
        const { t, companies } = this.props;

        const { errorMessage, isDeleting, isLoading, canDelete } = this.state;

        if (isLoading || !companies || companies.length === 0) {
            return null;
        }

        if (!canDelete) {
            return (<ConfirmationMessage>
                <Header>{t('components.CompanyDeleteCard.You cannot delete the company')}</Header>

                <Message>
                    <p>{t('components.CompanyDeleteCard.To delete this company you must first delete all associated production units')}</p>
                </Message>

                <Button className='btn-vincotte-padding secondary-btn' onClick={this.onClose}>
                    {t('components.CompanyDeleteCard.Close')}
                </Button>
            </ConfirmationMessage>);
        }

        const companiesToDelete = companies.map((company) => (
            {
                id: 0,
                reference: company.moniker,
                name: company.name,
                summary: {}
            } as ItemToDelete
        ));

        const title = t('components.CompanyDeleteCard.Are you sure you want to delete this company?');
        const description = t('components.CompanyDeleteCard.By deleting the following company, you will also delete the related data');

        return (
            <DeleteCard
                title={title}
                description={description}
                onClose={this.onClose}
                onDelete={this.onDelete}
                items={companiesToDelete}
                errorMessage={errorMessage}
                isDeleting={isDeleting} />
        );
    }
}

const CompanyDeleteCardWithTranslation = withTranslation()(CompanyDeleteCard);
const CompanyDeleteCardWithClientsClient = withAxiosService(ClientsClient)<
    'ClientsClient',
    ComponentPropsWithoutRef<typeof CompanyDeleteCardWithTranslation>
>(CompanyDeleteCardWithTranslation);

export { CompanyDeleteCardWithClientsClient as CompanyDeleteCard };
