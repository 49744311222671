import { TFunction } from 'react-i18next';
import { FileForUpload } from './FileForUpload';
import { FileUploadAdapter } from './FileUploadAdapter';

const FileUploadHelper = {
    upload: <T = any>(files: FileList, uploadAdapter: FileUploadAdapter<T>, t: TFunction<string>) : Array<Promise<T>> => {
        const promises = new Array<Promise<any>>();

        Array.from(files).forEach((file) => {
            const promise = uploadAdapter.addFile(FileForUpload.fromFile(file)).catch((error: Error) => {
                if (error.name === 'Conflict' || error.message.indexOf('already exists') !== -1) {
                    alert(t('components.FileUpload.File already exists'));
                    return;
                }

                throw error;
            });


            promises.push(promise);
        });

        return promises;
    }
};

export { FileUploadHelper };