import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { App } from './App';
import '../registerServiceWorker';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <StrictMode>
        <Router>
            <Routes>
                <Route path='*' element={<App />} />
            </Routes>
        </Router>
    </StrictMode>
);