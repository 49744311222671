import { IAssetFilesByAssetVm, IExportAsPdfVm, IFileDTO, IFileDto3, IFileDto4, IFilesRelatedToInspectionVm } from '../api/vincotte.via.api';
import { File as CoreFile } from '../core/File';

type File = CoreFile | IFileDto3 | IFileDto4 | IFileDTO | IFilesRelatedToInspectionVm | IAssetFilesByAssetVm | IExportAsPdfVm;

export class FileProxyHelper {
    static getFileId(file: File) {
        return 'fileId' in file && file.fileId ? file.fileId : ('id' in file ? file.id : undefined);
    }

    static isExternal(file: File) {
        if (!('isExternal' in file)) {
            return false;
        }

        if (!('mediaLink' in file)) {
            return false;
        }

        return file.isExternal && file.mediaLink.length > 0;
    }

    static createMediaLink(file: File) {
        const fileId = FileProxyHelper.getFileId(file);

        return FileProxyHelper.isExternal(file)
            ? (file as Record<string, any>).mediaLink
            : `/files/download/${fileId}/${file.fileName}`;
    }
    static createThumbnailLink(file: File) {
        const fileId = FileProxyHelper.getFileId(file);

        return FileProxyHelper.isExternal(file)
            ? (file as Record<string, any>).mediaLink
            : `/files/download/thumbnail/${fileId}/${file.fileName}`;
    }
}