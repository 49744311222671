import { ChangeEvent, Component, createRef, ReactNode, RefObject } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FileUploadProgressController, Progress, ProgressList } from '../FileUploadProgress';
import { CustomFileInput } from '../Form';
import { withFileUploadProgress } from '../../HOC/withFileUploadProgress';
import { FileUploadAdapter, FileUploadAdapterOnCompleted, FileUploadAdapterOnProgress } from './FileUploadAdapter';
import { FileUploadHelper } from './FileUploadHelper';
import { Medialink } from './Medialink';
import './FileUploadInput.css';

type FileUploadInputProps = WithTranslation & {
    children: ReactNode;
    uploadAdapter: FileUploadAdapter;
    uploadProgress: FileUploadProgressController;
};

type NewFile = {
    files?: FileList;
    medialink: string;
}

type FileUploadInputState = {
    newFile: NewFile;
    uploadProgress: Record<string, any>;
};

class FileUploadInput extends Component<FileUploadInputProps, FileUploadInputState> {

    private readonly InputRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();

    constructor(props: Readonly<FileUploadInputProps>) {
        const { uploadAdapter, uploadProgress } = props;
        uploadAdapter.fileUploadProgressController = uploadProgress;

        super(props);

        this.state = {
            newFile: {
                medialink: '',
            },
            uploadProgress: {},
        };
    }

    componentDidMount() {
        this.prepareAdapter();

        FileUploadAdapter.OnProgress.add(this.onProgress);
        FileUploadAdapter.OnCompleted.add(this.onCompleted);
        FileUploadAdapter.OnCancelled.add(this.onCompleted);
        FileUploadAdapter.OnFailure.add(this.onCompleted);
    }

    componentDidUpdate() {
        this.prepareAdapter();
    }

    prepareAdapter() {
        const { uploadAdapter, uploadProgress } = this.props;
        uploadAdapter.fileUploadProgressController = uploadProgress;
    }

    componentWillUnmount() {
        FileUploadAdapter.OnProgress.removeCallback(this.onProgress);
        FileUploadAdapter.OnCompleted.removeCallback(this.onCompleted);
        FileUploadAdapter.OnCancelled.removeCallback(this.onCompleted);
        FileUploadAdapter.OnFailure.removeCallback(this.onCompleted);
    }

    onProgress = ({ reference , data } : FileUploadAdapterOnProgress) => {
        const { uploadAdapter } = this.props;

        if (JSON.stringify(uploadAdapter.reference) !== JSON.stringify(reference)) {
            return;
        }

        const { uploadProgress } = this.state;

        const { id, label, percentage, loaded, total, abortController } = data;
        uploadProgress[id] = { label, percentage, loaded, total, abortController };
        this.setState({ uploadProgress });
    }

    onCompleted = ({ reference , data } : FileUploadAdapterOnCompleted) => {
        const { uploadAdapter } = this.props;
        if (JSON.stringify(uploadAdapter.reference) !== JSON.stringify(reference)) {
            return;
        }

        const { id } = data;
        const { uploadProgress } = this.state;
        delete uploadProgress[id];
        this.setState({ uploadProgress });
    }

    handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const name = target.name;

        const value = target.type === 'file' ? target.files : target.value;
        const { newFile } = this.state;

        (newFile as Record<string, any>)[name] = value;
        this.setState({ newFile });
    };

    onClick = () => {
        const { t, uploadAdapter } = this.props;

        if (!window.navigator.onLine) {
            alert(t('components.FileUpload.It is not allowed to upload files while offline'));
            return;
        }

        const { newFile } = this.state;

        if (newFile.files && newFile.files.length > 0) {
            FileUploadHelper.upload(newFile.files, uploadAdapter, t);
            newFile.files = undefined;

            if (this.InputRef.current !== null) {
                this.InputRef.current.value = '';
                this.InputRef.current.dispatchEvent(new Event('input', {
                    bubbles: true,
                    cancelable: true,
                }));
            }
        }

        if (newFile.medialink && newFile.medialink.length > 0) {
            uploadAdapter.addMediaLink(Medialink.fromMediaLink(newFile.medialink)).catch((error: Error) => {
                if (error.name === 'Conflict' || error.message.indexOf('already exists') !== -1) {
                    alert(t('components.FileUpload.File already exists'));
                    return;
                }

                throw error;
            });

            newFile.medialink = '';
        }
        this.setState({ newFile });
    }

    render() {
        const { t, children } = this.props;
        const { newFile, uploadProgress } = this.state;

        const onProgress = Object.values(uploadProgress);
        
        return (
            <div className="upload-file-line pb-2">
                <CustomFileInput multiple={true} name='files' onChange={this.handleOnChange} ref={this.InputRef} />

                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        value={newFile.medialink}
                        name="medialink"
                        onChange={this.handleOnChange}
                        placeholder={t('components.FileUpload.Or enter file link')}
                    />
                </div>

                <button type='button' className="btn btn-outline-vincotte" onClick={this.onClick} >
                    {children}
                </button>

                {onProgress.length > 0 &&
                <ProgressList>{onProgress.map((row, key) => (
                    <Progress key={key} completed={row.percentage} abortController={row.abortController} >{row.label}</Progress>
                ))}</ProgressList>}
            </div>
        );
    }
}

const FileUploadInputWithTranslation = withTranslation()(FileUploadInput);
const FileUploadInputWithFileUploadProgress = withFileUploadProgress()(FileUploadInputWithTranslation);
export { FileUploadInputWithFileUploadProgress as FileUploadInput };
