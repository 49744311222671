import { Component, ReactNode } from 'react';

type TitleProps = {
    children?: ReactNode;
}

export class Title extends Component<TitleProps> {
    render() {
        return (<h3 className="mt-3">{this.props.children}</h3>);
    }
}
